import React from "react";
import Image from "next/image";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { brands } from "@fortawesome/fontawesome-svg-core/import.macro";

function Home() {
  return (
    <>
      <Container>
        <Row>
          <Col className="text-center mx-md-auto my-5" md="6" sm="12">
            <div className="text-center mx-lg-auto">
              <div className="mb-5">
                <h1 className="display-5 fw-normal mb-2">
                  Enable communities to help each other
                </h1>
                <p className="lead">Build community. Keep yours.</p>
              </div>
              <div className="position-relative mt-5">
                <Image
                  src="/static/images/hero.png"
                  width={2626}
                  height={1678}
                  layout="responsive"
                />
              </div>
            </div>
          </Col>
          <Col className="w-md-75 w-lg-50 text-center mx-md-auto my-5" md="12">
            <h5 className="text-muted text-uppercase mb-5">
              Proudly supporting these local restaurants
            </h5>
            <Image src="/static/images/partner.png" width={773} height={160} />
          </Col>
        </Row>
      </Container>
      <div className="bg-white">
        <Container className="my-5 py-5">
          <Row>
            <Col md="6" sm="12">
              <iframe
                className="rounded-3"
                width="100%"
                height={450}
                src="https://www.youtube.com/embed/C_H0DUh-YdM?modestbranding=1&amp;rel=0"
                frameBorder="0"
                color="white"
              ></iframe>
            </Col>
            <Col className="my-5 px-5 align-self-center" md="6" sm="12">
              <h2>Mayo enables people to help others nearby in the moment</h2>
              <p className="fs-4">
                The positive effects of kindness are experienced in the brain of
                everyone who witnessed the act, improving their mood and making
                them significantly more likely to “pay it forward.”
              </p>
              <p className="fs-4">
                Science has found that the people who tend to cope best are the
                people who help others.
              </p>
              <p className="fs-4">
                In the current crisis and beyond, people need a new avenue and
                platform to channel their kindness.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      <Container>
        <Row className="py-5">
          <Col className="text-center mx-md-auto" md="12">
            <div className="px-5 mt-3">
              <h3>
                Mayo is building a symbiotic relationship between local
                residents and businesses
              </h3>
              <p className="lead">
                A step towards more connected neighborhood for users and local
                businesses.
              </p>
            </div>
          </Col>
          <Col md="6">
            <a href="https://www.heymayo.com/spreadkindness">
              <Image
                className="p-5"
                src="/static/images/community-apt.png"
                width={733}
                height={551}
                layout="responsive"
              />
            </a>
          </Col>
          <Col md="6">
            <a href="https://www.heymayo.com/helping-businesses">
              <Image
                className="p-5"
                src="/static/images/community-bus.png"
                width={733}
                height={551}
                layout="responsive"
              />
            </a>
          </Col>
        </Row>
      </Container>
      <div className="bg-success text-white px-5">
        <Row className="py-5">
          <Col className="mx-md-auto" md="8">
            <div className="fs-1">Get our mobile app</div>
          </Col>
          <Col className="mx-md-auto" md="4">
            <a className="text-white display-3 me-5" href="" target="_blank">
              <FontAwesomeIcon icon={brands("google-play")} />
            </a>
            <a
              className="text-white display-3"
              href=""
              rel="noopener noreferrer nofollow"
            >
              <FontAwesomeIcon icon={brands("app-store-ios")} />
            </a>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default React.memo(Home);
